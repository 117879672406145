import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLogin: false,
  user: {},
  cards: [],
  managers: {},
  subscription: {},
  isLoading: false,
  languages: []
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case actionTypes.USER_LOGIN:
      return {
        ...state,
        isLogin: true,
        user: action.payload.user
      };
    case actionTypes.GOOGLE_SIGNUP:
      return {
        ...state,
        user: action.payload
      };
    case actionTypes.USER_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload
      };
    case actionTypes.UPDATE_ACCOUNT:
      return {
        ...state,
        isLogin: true,
        user: action.payload
      };
    case actionTypes.UPDATE_PASSWORD:
      return {
        ...state,
        token: action.payload
      };
    case actionTypes.USER_CARDS:
      return {
        ...state,
        cards: action.payload
      };
    case actionTypes.USER_MANAGERS:
      return {
        ...state,
        managers: action.payload
      };
    case actionTypes.USER_LANGUAGES:
      return {
        ...state,
        languages: action.payload
      };
    case actionTypes.USER_LOGOUT:
      return {
        isLogin: false,
        token: '',
        user: {},
        cards: [],
        subscription: {},
        managers: {},
        languages: state.languages
      };
    case actionTypes.OTP_TOKEN:
      return {
        ...state,
        token: action.payload.token
      };
    default:
      return state;
  }
};

export default UserReducer;
