export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_CARDS = 'USER_CARDS';
export const OTP_TOKEN = 'OTP_TOKEN';
export const USER_MANAGERS = 'USER_MANAGERS';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const USER_PAYMENT = 'USER_PAYMENT';
export const GOOGLE_SIGNUP = 'GOOGLE_SIGNUP';
export const USER_SUBSCRIPTION = 'USER_SUBSCRIPTION';
export const SET_LOADING = 'SET_LOADING';
export const USER_LANGUAGES = 'USER_LANGUAGES';
