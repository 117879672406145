import * as actionTypes from '../actions/actionTypes';

const initialState = {
  bookings: {},
  earnings: {},
  isLoading: false
};

const BookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case actionTypes.ALL_BOOKING:
      return {
        ...state,
        bookings: action.payload
      };
    case actionTypes.ALL_USER_BOOKINGS:
      return {
        ...state,
        bookings: action.payload
      };
    case actionTypes.USER_EARNING:
      return {
        ...state,
        earnings: action.payload
      };
    case actionTypes.CLEAR_BOOKINGS:
      return {
        bookings: {},
        earnings: {},
        isLoading: false
      };
    default:
      return state;
  }
};

export default BookingReducer;
