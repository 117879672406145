import { Suspense, useEffect } from 'react';
import './App.css';
import './assets/css/notification.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';

import Loading from './shared/Loading';
import NavigationRoutes from './routes/NavigationRoutes';
import ErrorBoundary from './shared/ErrorBoundary';
import ErrorBoundaryAlert from './shared/ErrorBoundaryAlert';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguages, setSocket } from './store/storeIndex';
import Socket from './Socket';
import { setActiveUsers } from './store/storeIndex';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import './i18n'; // Import your i18n configuration file

const AOS = require('aos');
function App() {
  // const userId = useSelector((state) => state.user.user._id);
  // useEffect(() => {
  //   Socket.emit('join', { userId });
  //   Socket.on('getUsers', () => {});
  // }, [userId]);

  const isLogin = useSelector((state) => state.user.isLogin);
  const dispatch = useDispatch();
  const User = useSelector((state) => state.user.user);
  const socket = useSelector((state) => state.socket.socket);

  useEffect(() => {
    dispatch(getLanguages());
  }, [dispatch]);

  useEffect(() => {
    if (!isLogin) return;
    dispatch(setSocket(Socket));
  }, [isLogin, dispatch, Socket]);

  useEffect(() => {
    if (socket === null || !isLogin) return;
    socket.emit('addNewUser', User._id);
    socket.on('getOnlineUsers', (res) => {
      dispatch(setActiveUsers(res));
    });

    return () => {
      socket.off('getOnlineUsers');
    };
  }, [isLogin, socket, User._id, dispatch]);

  useEffect(() => {
    AOS.init(
      {
        offset: 200,
        duration: 800,
        easing: 'ease-in-out-sine',
        delay: 200,
        mirror: true
      },
      []
    );
  });
  AOS.init();

  useEffect(() => {
    document.dir = i18n.dir();
  }, [i18n]);

  return (
    <ErrorBoundary fallback={<ErrorBoundaryAlert />}>
      <Suspense fallback={<Loading />}>
        <I18nextProvider i18n={i18n}>
          <ProSidebarProvider>
            <Router>
              <NavigationRoutes />
            </Router>
          </ProSidebarProvider>
        </I18nextProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
