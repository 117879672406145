import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

const EditAccount = lazy(() => import('../pages/accounts/EditAccount.js'));
const Messages = lazy(() => import('../pages/Messages'));
const CustomerBooking = lazy(() => import('../pages/customer/CustomerBooking'));
const PaymentHistory = lazy(() => import('../pages/PaymentHistory'));
const CustomerSpaces = lazy(() => import('../pages/customer/CustomerSpaces.js'));
const Notifications = lazy(() => import('../pages/Notifications'));
const SingleSpaceCustomer = lazy(() => import('../pages/SingleSpaceCustomer'));
const AccountPrivacyPolicy = lazy(
  () => import('../components/account setting/AccountPrivacyPolicy')
);
const AccountPaymentHistory = lazy(
  () => import('../components/account setting/AccountPaymentHistory')
);
const AccountFaqs = lazy(() => import('../components/account setting/AccountFaqs'));
const ChangeAccountPassword = lazy(
  () => import('../components/account setting/ChangeAccountPassword')
);
const AccountSetting = lazy(() => import('../pages/AccountSetting'));
const CustomerHome = lazy(() => import('../pages/customer/Home'));

function CustomerRoutes() {
  return (
    <>
      {' '}
      <Routes>
        <Route exact path="/customer" element={<CustomerHome />}></Route>
        <Route exact path="/all-spaces" element={<CustomerSpaces />}></Route>
        <Route
          exact
          path="/dashboard/customer/single-space/:sid"
          element={<SingleSpaceCustomer />}></Route>
        <Route exact path="/dashboard/messages/:id?" element={<Messages />}></Route>
        <Route exact path="/dashboard/bookings" element={<CustomerBooking />}></Route>
        <Route exact path="/dashboard/payment-history" element={<PaymentHistory />}></Route>
        <Route exact path="/dashboard/notifications" element={<Notifications />}></Route>
        <Route
          path="/settings/edit"
          element={
            <AccountSetting>
              <EditAccount />
            </AccountSetting>
          }
        />
        <Route
          path="/settings/changepassword"
          element={
            <AccountSetting>
              <ChangeAccountPassword />
            </AccountSetting>
          }
        />
        <Route
          path="/settings/faq"
          element={
            <AccountSetting>
              <AccountFaqs />
            </AccountSetting>
          }
        />
        <Route
          path="/settings/privacy"
          element={
            <AccountSetting>
              <AccountPrivacyPolicy />
            </AccountSetting>
          }
        />
        <Route
          path="/settings/payment"
          element={
            <AccountSetting>
              <AccountPaymentHistory />
            </AccountSetting>
          }
        />
        <Route path="*" element={<Navigate to="/customer" />} />
      </Routes>
    </>
  );
}

export default CustomerRoutes;
