import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
const EditAccount = lazy(() => import('../pages/accounts/EditAccount.js'));
const Home = lazy(() => import('../pages/Home'));
const Addspaces = lazy(() => import('../pages/Addspaces.js'));
const Messages = lazy(() => import('../pages/Messages'));
const Bookings = lazy(() => import('../pages/Bookings'));
const PaymentHistory = lazy(() => import('../pages/PaymentHistory'));
const MyManagers = lazy(() => import('../pages/MyManagers'));
const ViewAllSpaces = lazy(() => import('../pages/AllSpaces'));
const Notifications = lazy(() => import('../pages/Notifications'));
const SingleSpace = lazy(() => import('../pages/SingleSpace'));
const UpdateSpace = lazy(() => import('../pages/Updatespaces.js'));

const AccountPrivacyPolicy = lazy(
  () => import('../components/account setting/AccountPrivacyPolicy')
);
const AccountPaymentHistory = lazy(
  () => import('../components/account setting/AccountPaymentHistory')
);
const AccountSubscription = lazy(() => import('../components/account setting/AccountSubscription'));
const AccountFaqs = lazy(() => import('../components/account setting/AccountFaqs'));
const ChangeAccountPassword = lazy(
  () => import('../components/account setting/ChangeAccountPassword')
);
const AccountSetting = lazy(() => import('../pages/AccountSetting'));

function StorageOwnerRoutes() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/dashboard/all-spaces" element={<ViewAllSpaces />}></Route>
        <Route exact path="/dashboard/addspace" element={<Addspaces />}></Route>
        <Route exact path="/dashboard/single-space/:sid" element={<SingleSpace />}></Route>
        <Route exact path="/dashboard/update-space/:sid" element={<UpdateSpace />}></Route>
        <Route exact path="/dashboard/messages/:id?" element={<Messages />}></Route>
        <Route exact path="/dashboard/bookings" element={<Bookings />}></Route>
        <Route exact path="/dashboard/payment-history" element={<PaymentHistory />}></Route>
        <Route exact path="/dashboard/my-managers" element={<MyManagers />}></Route>
        <Route exact path="/dashboard/notifications" element={<Notifications />}></Route>
        <Route
          path="/settings/edit"
          element={
            <AccountSetting>
              <EditAccount />
            </AccountSetting>
          }
        />
        <Route
          path="/settings/changepassword"
          element={
            <AccountSetting>
              <ChangeAccountPassword />
            </AccountSetting>
          }
        />
        <Route
          path="/settings/faq"
          element={
            <AccountSetting>
              <AccountFaqs />
            </AccountSetting>
          }
        />
        <Route
          path="/settings/privacy"
          element={
            <AccountSetting>
              <AccountPrivacyPolicy />
            </AccountSetting>
          }
        />
        <Route
          path="/settings/payment"
          element={
            <AccountSetting>
              <AccountPaymentHistory />
            </AccountSetting>
          }
        />
        <Route
          path="/settings/subscription"
          element={
            <AccountSetting>
              <AccountSubscription />
            </AccountSetting>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

export default StorageOwnerRoutes;
