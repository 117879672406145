import * as actionTypes from './actionTypes';
import Toast from '../../../shared/Toast';
import Axios from '../../../axios/Axios';

export const getUserEarnings = (token) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.get(`bookings/userEarning`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      dispatch({
        type: actionTypes.USER_EARNING,
        payload: response.data
      });
    })
    .catch((error) => {
      Toast.error(error.response?.data.message);
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const getAllBookings =
  (userId, token, userRole, page, filterState, dayValue) => (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOADING,
      payload: true
    });
    let filterParam = '';
    if (filterState) {
      if (filterState === 'upcoming') {
        filterParam = 'upcoming=true';
      } else if (filterState === 'pending') {
        filterParam = 'status=pending';
      } else if (filterState === 'rejected') {
        filterParam = 'status=rejected';
      } else if (filterState === 'approved') {
        filterParam = 'status=approved';
      }
    }
    let dateParams = '';
    if (dayValue) {
      const [startDate, endDate] = dayValue;

      if (startDate && endDate) {
        const isoStartDate = new Date(startDate).toISOString();
        const isoEndDate = new Date(endDate).toISOString();

        dateParams = `&startDate=${isoStartDate}&endDate=${isoEndDate}`;
      } else {
        const isoStartDate = new Date(startDate).toISOString();
        dateParams = `&startDate=${isoStartDate}`;
      }
    }

    Axios.get(
      `bookings/user_bookings/${userId}?page=${page}&userType=${userRole}${
        filterParam ? `&${filterParam}` : ''
      }${dateParams}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_BOOKING,
          payload: response.data
        });
      })
      .catch((error) => {
        Toast.error(error.response?.data.message);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.SET_LOADING,
          payload: false
        });
      });
  };

export const getUserBookings =
  (userId, token, page = 1, filterBy = '') =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOADING,
      payload: true
    });
    Axios.get(`bookings/user_bookings/${userId}?page=${page}&filterBy=${filterBy}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_BOOKING,
          payload: response.data
        });
      })
      .catch((error) => {
        Toast.error(error.response?.data.message);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.SET_LOADING,
          payload: false
        });
      });
  };

export const getAllUserBookings =
  (userId, token, userType = 'Customer') =>
  (dispatch) => {
    Axios.get(`bookings/user_bookings/${userId}?userType=${userType}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_USER_BOOKINGS,
          payload: response.data
        });
      })
      .catch((error) => {
        Toast.error(error.response?.data.message);
      });
  };

export const getOwnerBookings =
  (userId, token, page = 1, filterBy = '') =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOADING,
      payload: true
    });
    Axios.get(`bookings/user_bookings/${userId}?page=${page}&filterBy=${filterBy}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_BOOKING,
          payload: response.data
        });
      })
      .catch((error) => {
        Toast.error(error.response?.data.message);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.SET_LOADING,
          payload: false
        });
      });
  };

export const getManagerBookings =
  (userId, token, page = 1, filterBy = '') =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOADING,
      payload: true
    });
    Axios.get(`bookings/user_bookings/${userId}?page=${page}&filterBy=${filterBy}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_BOOKING,
          payload: response.data
        });
      })
      .catch((error) => {
        Toast.error(error.response?.data.message);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.SET_LOADING,
          payload: false
        });
      });
  };

export const createBooking = (data, token, onHide) => (dispatch) => {
  console.log(data);
  Axios.post('bookings/create_booking', data, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => {
      onHide();
      dispatch(getUserBookings(data.userId, token));
      dispatch(getOwnerBookings(data.userId, token));
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response?.data.message);
    });
};
