import { useSelector } from 'react-redux';
import StorageOwnerRoutes from './StorageOwnerRoutes.js';
import CustomerRoutes from './CustomerRoutes.js';
import DiverRoutes from './DiverRoutes.js';
import ManagerRoutes from './ManagerRoutes.js';
import GlobalRoutes from './GlobalRoutes.js';

const NavigationRoutes = () => {
  const isLogin = useSelector((state) => state.user.isLogin);
  const userRole = useSelector((state) => state.user.user.role);
  const routes = isLogin ? (
    userRole === 'Storage Owner' ? (
      <StorageOwnerRoutes />
    ) : userRole === 'Customer' ? (
      <CustomerRoutes />
    ) : userRole === 'Manager' ? (
      <ManagerRoutes />
    ) : (
      userRole === 'Truck Driver' && <DiverRoutes />
    )
  ) : (
    <GlobalRoutes />
  );
  return routes;
};
export default NavigationRoutes;
