import React from 'react';
import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
const ProviderLogin = lazy(() => import('../pages/provider/ProviderLogin'));
const ProviderSubscription = lazy(() => import('../pages/provider/ProviderSubscription'));
const ProviderRegister = lazy(() => import('../pages/provider/ProviderRegister'));
const ProviderVerifyOTP = lazy(() => import('../pages/provider/ProviderVerifyOTP'));
const ProviderResetPassword = lazy(() => import('../pages/provider/ProviderResetPassword'));
const ProviderCompanyInfo = lazy(() => import('../pages/provider/ProviderCompanyInfo'));
const Login = lazy(() => import('../pages/customer/Login'));
const Register = lazy(() => import('../pages/customer/Register'));
const ManagerRegister = lazy(() => import('../pages/mangers/Mangerregister'));
const VerifyOTP = lazy(() => import('../pages/customer/VerifyOTP'));
const ForgetPassword = lazy(() => import('../pages/customer/ForgetPassword'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const PersonalInfo = lazy(() => import('../pages/customer/PersonalInfo'));
const CustomerInfo = lazy(() => import('../pages/customer/CustomerInfo'));
const CompanyInfo = lazy(() => import('../pages/CompanyInfo'));
const DriverCompanyInfo = lazy(() => import('../pages/truck-driver/DriverCompanyInfo'));
function GlobalRoutes() {
  return (
    <Routes>
      <Route exact path="/auth/login" element={<Login />}></Route>
      <Route exact path="/auth/customer/register" element={<Register />}></Route>
      <Route exact path="/auth/customer/verify-otp" element={<VerifyOTP />}></Route>
      <Route exact path="/auth/verify-otp" element={<VerifyOTP />}></Route>
      <Route exact path="/auth/service-provider/login" element={<ProviderLogin />}></Route>
      <Route exact path="/auth/service-provider/register" element={<ProviderRegister />}></Route>
      <Route
        exact
        path="/auth/service-provider/verify-otp"
        all-spaces
        element={<ProviderVerifyOTP />}></Route>
      <Route
        exact
        path="/auth/service-provider/reset-password"
        element={<ProviderResetPassword />}></Route>
      <Route
        exact
        path="/auth/service-provider/company-information"
        element={<ProviderCompanyInfo />}></Route>
      <Route exact path="/auth/forget-password" element={<ForgetPassword />}></Route>
      <Route exact path="/auth/verify-otp" element={<VerifyOTP />}></Route>
      <Route exact path="/auth/reset-password" element={<ResetPassword />}></Route>
      <Route exact path="/auth/personal-information" element={<PersonalInfo />}></Route>
      <Route exact path="/auth/subscription" element={<ProviderSubscription />}></Route>
      <Route exact path="/auth/customer-information" element={<CustomerInfo />}></Route>
      <Route exact path="/auth/manager/register" element={<ManagerRegister />}></Route>
      <Route exact path="/auth/company-information" element={<CompanyInfo />}></Route>
      <Route exact path="/auth/driver/company-information" element={<DriverCompanyInfo />}></Route>

      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
}

export default GlobalRoutes;
