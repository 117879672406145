import React from 'react';
import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

const EditAccount = lazy(() => import('../pages/accounts/EditAccount.js'));
const Messages = lazy(() => import('../pages/Messages'));
const Bookings = lazy(() => import('../pages/Bookings'));
const PaymentHistory = lazy(() => import('../pages/PaymentHistory'));
const AccountPrivacyPolicy = lazy(
  () => import('../components/account setting/AccountPrivacyPolicy')
);
const AccountPaymentHistory = lazy(
  () => import('../components/account setting/AccountPaymentHistory')
);
const AccountFaqs = lazy(() => import('../components/account setting/AccountFaqs'));
const ChangeAccountPassword = lazy(
  () => import('../components/account setting/ChangeAccountPassword')
);
const AccountSetting = lazy(() => import('../pages/AccountSetting'));

function ManagerRoutes() {
  return (
    <Routes>
      <Route exact path="/dashboard/messages/:id?" element={<Messages />}></Route>
      <Route exact path="/dashboard/bookings" element={<Bookings />}></Route>
      <Route exact path="/dashboard/payment-history" element={<PaymentHistory />}></Route>
      <Route
        path="/settings/edit"
        element={
          <AccountSetting>
            <EditAccount />
          </AccountSetting>
        }
      />
      <Route
        path="/settings/changepassword"
        element={
          <AccountSetting>
            <ChangeAccountPassword />
          </AccountSetting>
        }
      />
      <Route
        path="/settings/faq"
        element={
          <AccountSetting>
            <AccountFaqs />
          </AccountSetting>
        }
      />
      <Route
        path="/settings/privacy"
        element={
          <AccountSetting>
            <AccountPrivacyPolicy />
          </AccountSetting>
        }
      />
      <Route
        path="/settings/payment"
        element={
          <AccountSetting>
            <AccountPaymentHistory />
          </AccountSetting>
        }
      />
      <Route path="*" element={<Navigate to="/dashboard/bookings" />} />
    </Routes>
  );
}

export default ManagerRoutes;
