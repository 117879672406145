/* eslint-disable no-unused-vars */
import * as actionTypes from './actionTypes';
import * as catTypes from '../../category/actions/actionTypes';
import * as chatTypes from '../../chat/actions/actionTypes';
import * as spaceTypes from '../../space/actions/actionTypes';
import * as vehTypes from '../../vehicle/actions/actionTypes';
import * as notiTypes from '../../notification/actions/actionTypes';
import Toast from '../../../shared/Toast';
import Axios from '../../../axios/Axios';
import { getAllSpaces, getUserSpaces } from '../../storeIndex';
import i18n from 'i18next';

export const userLogin = (data, navigation) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.post('users/login', data)
    .then((response) => {
      dispatch({
        type: actionTypes.USER_LOGIN,
        payload: response.data
      });
      const role = response?.data?.user?.role;
      if (role === 'Customer') {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              };
              dispatch(getAllSpaces(navigation, 1, center));
            },
            (err) => {
              console.log(err.message);
              Toast.error(' Allow location permission for enhanced experiences.');
            }
          );
        } else {
          Toast.error('Geolocation is not supported by this browser.');
        }
      } else {
        dispatch(getUserSpaces());
      }
      navigation('/');
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(i18n.t(error.response.data.message));
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const userLogout = (navigation) => (dispatch) => {
  console.log(navigation);
  dispatch({
    type: actionTypes.USER_LOGOUT
  });
  dispatch({
    type: catTypes.CLEAR_CATEGORIES
  });
  dispatch({
    type: chatTypes.CLEAR_CHAT
  });
  dispatch({
    type: spaceTypes.CLEAR_SPACES
  });
  dispatch({
    type: vehTypes.CLEAR_VEHICLES
  });
  dispatch({
    type: notiTypes.CLEAR_NOTIFICATIONS
  });
  navigation('/auth/login');
  Toast.success('User logout successful');
};

export const forgetPassword = (data, navigate, moveTo) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.post('users/forgotpassword', data)
    .then((response) => {
      navigate('/auth/customer/verify-otp', {
        state: {
          email: data.email,
          moveTo
        }
      });
      Toast.success(response.data.status);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const verifyOtp =
  (data, navigate, moveTo = '/auth/customer/reset-password', role) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOADING,
      payload: true
    });
    Axios.post('users/verifyotp', data)
      .then((response) => {
        navigate(moveTo, {
          state: {
            email: data.email,
            role
          }
        });
        Toast.success(response.data.message);
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
        if (error.response.data.message === 'you are not logged in! please login again') {
          dispatch(userLogout(navigate));
        }
      })
      .finally(() => {
        dispatch({
          type: actionTypes.SET_LOADING,
          payload: false
        });
      });
  };

export const resetPassword = (data, navigate) => (dispatch) => {
  Axios.patch('users/resetPassword', data)
    .then((response) => {
      dispatch({
        type: actionTypes.UPDATE_PASSWORD,
        payload: response.data
      });
      const role = response?.data?.user?.role;
      if (role === 'Customer') {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              };
              dispatch(getAllSpaces(navigate, 1, center));
            },
            (err) => {
              console.log(err.message);
              Toast.error(' Allow location permission for enhanced experiences.');
            }
          );
        } else {
          Toast.error('Geolocation is not supported by this browser.');
        }
      } else {
        dispatch(getUserSpaces());
      }
      navigate('/');
      Toast.success(response.data.status);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};
export const googleLogin = (token, email, navigate) => (dispatch) => {
  const data = {
    token,
    email
  };

  Axios.post('users/google-login', data)
    .then((response) => {
      if (response.data.url) {
        dispatch({
          type: actionTypes.GOOGLE_SIGNUP,
          payload: response.data.user
        });
        navigate(response.data.url, {
          state: {
            email: response.data.user.email,
            role: response.data.user.role
          }
        });
      } else {
        dispatch({
          type: actionTypes.USER_LOGIN,
          payload: response.data
        });
        navigate('/customer');
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
export const signupUser = (data, navigate, moveTo, role) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.post('users/signup', data)
    .then((response) => {
      if (moveTo === '/auth/personal-information' && role === 'Business') {
        navigate('/auth/service-provider/verify-otp', {
          state: {
            email: data.email,
            moveTo,
            role
          }
        });
      } else {
        navigate('/auth/customer/verify-otp', {
          state: {
            email: data.email,
            moveTo,
            role
          }
        });
      }
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const updateUserProfile = (data, navigate, token) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.patch('users/UpdateUserProfile', data, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      const role = response?.data?.data?.user?.role;
      if (role === 'Customer') {
        dispatch({
          type: actionTypes.UPDATE_ACCOUNT,
          payload: response.data.data.user
        });
        navigate('/customer');
      } else {
        dispatch({
          type: actionTypes.UPDATE_ACCOUNT,
          payload: response.data.data.user
        });
        navigate('/');
      }
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
      if (error.response.data.message === 'you are not logged in! please login again') {
        dispatch(userLogout(navigate));
      }
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const updateCompanyProfile = (data, navigate, token, role) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.patch('users/UpdateCompanyProfile', data, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      // dispatch({
      //   type: actionTypes.UPDATE_ACCOUNT,
      //   payload: response.data.data.user
      // });
      navigate('/auth/subscription');
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const sendManagerInvitation = (data, token, onHide) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.post('users/manager-invitation', data, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      onHide();
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const signupManager = (data, navigate, moveTo, role) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.patch('users/manager_resgister', data)
    .then((response) => {
      navigate('/auth/verify-otp', {
        state: {
          email: data.email,
          moveTo,
          role
        }
      });
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
      if (error.response.data.message === 'you are not logged in! please login again') {
        dispatch(userLogout(navigate));
      }
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const getUserCards = (userId, token) => (dispatch) => {
  Axios.get(`users/user-cards/`)
    .then((response) => {
      dispatch({
        type: actionTypes.USER_CARDS,
        payload: response.data.cards.data
      });
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};

export const getSubscription = () => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.get('/subscription/user-subscription')
    .then((response) => {
      dispatch({
        type: actionTypes.USER_SUBSCRIPTION,
        payload: response.data.userSubscriptions
      });
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const createSubscription = (planId, navigate, setLoading) => (dispatch) => {
  if (setLoading) {
    setLoading(true);
  }
  Axios.post('/subscription', {
    planId
  })
    .then((planResp) => {
      dispatch({
        type: actionTypes.USER_LOGIN,
        payload: planResp.data
      });
      if (navigate) {
        navigate('/');
      }
      Toast.success(planResp.data.message);
      if (setLoading) {
        setLoading(false);
      }
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};

export const addUserSubscription = (data, token, onHide, navigate, planId) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.post('users/add_card', data, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      dispatch(createSubscription(planId, navigate));
      onHide();
      dispatch(getUserCards(data.userId, token));
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const addUserCard = (data, token, onHide) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.post('users/add_card', data, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      onHide();
      dispatch(getUserCards(data.userId, token));
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const deleteUserCard = (data, token) => (dispatch) => {
  Axios.post(`users/delete_card`, data, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      dispatch(getUserCards());
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};

export const getOwnerManagers =
  (page = 1, filterBy = '') =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOADING,
      payload: true
    });
    Axios.get(`users/owner-managers/?page=${page}&filterBy=${filterBy}`)
      .then((response) => {
        dispatch({
          type: actionTypes.USER_MANAGERS,
          payload: response.data
        });
      })
      .catch((error) => {
        Toast.error(error.response?.data?.message);
      })
      .finally(() => {
        dispatch({
          type: actionTypes.SET_LOADING,
          payload: false
        });
      });
  };
export const deleteManager = (managerId, handleClose) => (dispatch) => {
  Axios.delete(`users/owner-managers/${managerId}`)
    .then((response) => {
      dispatch(getOwnerManagers());
      handleClose();
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};
export const getPayments =
  (id, token, page = 1) =>
  (dispatch) => {
    Axios.get(`users/owner-managers/${id}?page=${page}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((response) => {
        dispatch({
          type: actionTypes.USER_PAYMENT,
          payload: response.data
        });
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
        console.log(error.response.data);
      });
  };

export const updateUserAccount = (data, token) => (dispatch) => {
  Axios.patch('users/updateMe', data, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      console.log(response.data.data.user);
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        payload: response.data.data.user
      });
      Toast.success('Update successful');
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
      console.log(error.response.data);
    });
};

export const updateUserPassword = (data, token) => (dispatch) => {
  Axios.patch('users/updatePassword', data, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      dispatch({
        type: actionTypes.UPDATE_PASSWORD,
        payload: response.data.token
      });
      Toast.success('Update successful');
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
      console.log(error.response.data);
    });
};

export const userSubscription = (data, navigation) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.post('users/subscription', data)
    .then((response) => {
      dispatch({
        type: actionTypes.USER_LOGIN,
        payload: response.data
      });
      navigation('/');
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const getLanguages = () => (dispatch) => {
  Axios.get(`language`)
    .then((response) => {
      dispatch({
        type: actionTypes.USER_LANGUAGES,
        payload: response.data.languages
      });
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};
