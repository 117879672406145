/* eslint-disable no-unused-vars */
import * as actionTypes from './actionTypes';
import Toast from '../../../shared/Toast';
import Axios from '../../../axios/Axios';
import { userLogout } from '../../storeIndex';

export const getUserSpaces =
  (navigate, page = 1, filterby = '') =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOADING,
      payload: true
    });
    Axios.get(`spaces/space/?page=${page}&filterby=${filterby}`)
      .then((response) => {
        dispatch({
          type: actionTypes.USER_SPACES,
          payload: response.data
        });
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
        if (
          error.response?.data.message == 'you are not logged in! please login again' &&
          error.response.data.error.statusCode === 401
        ) {
          dispatch(userLogout(navigate));
        }
      })
      .finally(() => {
        dispatch({
          type: actionTypes.SET_LOADING,
          payload: false
        });
      });
  };

export const getAllSpaces =
  (
    navigate,
    page = 1,
    center,
    filterby = '',
    Distance = 'all',
    rating = 0,
    price,
    handleClose,
    ...rest
  ) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOADING,
      payload: true
    });
    Axios.get(`spaces?page=${page}&filterby=${filterby}`);
    let facilitiesQuery = '';
    let servicesQuery = '';
    let amenitiesServicesQuery = '';
    if (rest.length < 1) {
      facilitiesQuery = '';
      servicesQuery = '';
      amenitiesServicesQuery = '';
    } else if (rest.length > 1) {
      facilitiesQuery = rest[0]?.map((facility) => `${facility.value}`).join(',');
      servicesQuery = rest[1]?.map((service) => `${service.value}`).join(',');
    } else {
      amenitiesServicesQuery = rest[0]?.map((service) => `${service.value}`).join(',');
    }
    Axios.get(
      `spaces?page=${page}${
        filterby === 'all' ? `` : `&filterby=${filterby}`
      }&distance=${Distance}${
        rating === 0 ? '' : `&rating=${rating}`
      }&facility=${facilitiesQuery}&service=${
        rest.length > 1 ? servicesQuery : amenitiesServicesQuery
      }&lnglat=${center?.lat},${center?.lng}&${price ? `price=${price}` : ''}`
    )
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_SPACES,
          payload: response.data
        });
        handleClose();
      })
      .catch((error) => {
        Toast.error(error?.response?.data?.message);
        if (
          error.response?.data.message == 'you are not logged in! please login again' &&
          error.response.data.error.statusCode === 401
        ) {
          dispatch(userLogout(navigate));
        }
      })
      .finally(() => {
        dispatch({
          type: actionTypes.SET_LOADING,
          payload: false
        });
      });
  };

export const getSingleSpace = (id, token) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.get(`spaces/single_space/${id}`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      dispatch({
        type: actionTypes.SINGLE_SPACE,
        payload: response.data.space
      });
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const addUserSpace = (data, navigate) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.post('spaces/add_space', data)
    .then((response) => {
      dispatch(getUserSpaces(navigate));
      navigate('/dashboard/all-spaces');
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
      if (error.response.data.message === 'you are not logged in! please login again') {
        dispatch(userLogout(navigate));
      }
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const updateUserSpace = (data, id, token, navigate) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: true
  });
  Axios.patch(`spaces/update_space/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      dispatch(getUserSpaces(navigate));
      navigate(`/dashboard/single-space/${id}`);
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
      if (error.response.data.message === 'you are not logged in! please login again') {
        dispatch(userLogout(navigate));
      }
    })
    .finally(() => {
      dispatch({
        type: actionTypes.SET_LOADING,
        payload: false
      });
    });
};

export const changeAvailability = (userId, data, navigate) => (dispatch) => {
  Axios.post('spaces/change-availability', data)
    .then((response) => {
      dispatch(getUserSpaces(navigate));
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};

export const deleteSpace = (userId, spaceId, token, handleClose) => (dispatch) => {
  Axios.delete(`spaces/delete-space/${spaceId}`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      dispatch(getUserSpaces());
      handleClose();
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};

export const getAreaSpace = (id, token) => (dispatch) => {
  Axios.post(`spaces/area-spaces`, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      // dispatch({
      //   type: actionTypes.SINGLE_SPACE,
      //   payload: response.data.space,
      // });
      console.log(response.data);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};
export const createReview = (data, token, onHide) => (dispatch) => {
  Axios.post(`spaces/add_review`, data, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then((response) => {
      onHide();
      dispatch(getSingleSpace(data.spaceId, token));
      Toast.success(response.data.message);
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};
