/* eslint-disable no-unused-vars */
import * as actionTypes from './actionTypes';
import Toast from '../../../shared/Toast';
import Axios from '../../../axios/Axios';
import axios from 'axios';
import { userLogout } from '../../storeIndex';

export const getUserConversations = (id, token) => (dispatch) => {
  Axios.get(`conversations/${id}`, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => {
      dispatch({
        type: actionTypes.USER_CONVERSATIONS,
        payload: response.data.userConversations
      });
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};

export const getConversationMessages = (id, token) => (dispatch) => {
  Axios.get(`messages/${id}`, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => {
      dispatch({
        type: actionTypes.CONVERSATION_MESSAGES,
        payload: response.data.messages
      });
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};
export const send_messages = (data, token) => (dispatch) => {
  Axios.post('messages', data, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => {
      dispatch({
        type: actionTypes.SEND_MESSAGES,
        payload: response.data.messages
      });
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};

export const send_media_messages = (data, token) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_SERVER_URL}api/v1/messages/media_message`, data, {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true
    })
    .then((response) => {
      dispatch({
        type: actionTypes.SEND_MESSAGES,
        payload: response.data.messages
      });
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
    });
};

export const startNewConversation = (data, token, navigate) => (dispatch) => {
  Axios.post('conversations/', data, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => {
      dispatch(getUserConversations(data.senderId, token));
      navigate('/dashboard/messages');
    })
    .catch((error) => {
      Toast.error(error.response.data.message);
      if (error.response.data.message === 'you are not logged in! please login again') {
        dispatch(userLogout(navigate));
      }
    });
};
